<template>
    <app-wrapper>
        <template v-slot:child>
            <div v-if="plan?.businessPlan === 'Micro Business Plan'">
                <access-denied />
            </div>
            <div v-else>
                <CreateProduct
                    :closeModal="() => toggleCreateProduct({ show: false, cat: 'PRODUCT' })"
                    :transactionCategory="'PRODUCT'"
                />
                <!--Raw Material -->
                <div class="w-90 center">
                    <div class="flex flex-wrap items-center justify-between items-center pt4">
                        <div>
                            <div v-if="$route.name === 'Produce'" class="pb3 pb0-l f3 b lh-copy">Produce</div>
                            <div v-else-if="$route.name === 'WorkInProgress'" class="pb3 pb0-l f3 b lh-copy">
                                Work In Progress (Raw Materials)
                            </div>
                            <div v-else class="pb3 pb0-l f3 b lh-copy">Raw material & Product (Items)</div>
                        </div>
                        <div class="flex scrollmenu">
                            <button class="mr2 flex items-center" v-show="$route.name !== 'Produce'">
                                <router-link :to="{ name: 'Produce' }"> Produce </router-link>
                            </button>
                            <router-link
                                :to="{ name: 'AddTransactionNew' }"
                                @click="changeBusinessType('purchases')"
                                v-if="$route.name === 'Produce'"
                                ><button style="background-color: white; color: #132c8c; border: 1px solid #132c8c">Buy</button>
                            </router-link>
                            <button
                                v-else
                                style="background-color: white; border: 1px solid #132c8c; color: #132c8c"
                                class="dropdown-togglex"
                                role="button"
                                id="dropdownMenuLink"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                More <font-awesome-icon icon="fa-solid fa-caret-down" class="fa-2x" />
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink" v-if="$route.name !== 'Produce'">
                                <li>
                                    <router-link
                                        :to="{ name: 'CreateWorkInProgress' }"
                                        v-if="state.wipStatus && $route.name !== 'CreateWorkInProgress'"
                                    >
                                        WIP
                                    </router-link>
                                </li>
                                <li @click="() => toggleCreateProduct({ show: true, cat: 'PRODUCT' })" style="cursor: pointer">
                                    Create Product
                                </li>
                                <li>
                                    <router-link :to="{ name: 'AddTransactionNew' }" @click="changeBusinessType('purchases')"
                                        >Buy</router-link
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- Drop down -->
                    <div>
                        <div class="dropdown">
                            <div
                                id="profile-dropdown"
                                class="profile-content box-border"
                                style="background: white; box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.08); border-radius: 6px"
                            >
                                <router-link :to="{ name: 'CreateRawMaterial' }" class="w-100">
                                    <div class="font-w1 pv2 ph3">Raw Material</div>
                                </router-link>
                                <!-- <router-link :to="{ name: 'CreateFinishedProduct' }" class="w-100">
                                    <div class="font-w1 pv2 ph3">Finished Product</div>
                                </router-link> -->
                            </div>
                        </div>
                    </div>
                    <!-- drop down -->
                </div>
                <!--Raw Material -->

                <!-- summary all product, draft archieve -->
                <div v-if="$route.name !== 'Produce'" class="w-90 center pt5">
                    <div class="flex scrollmenux">
                        <div class="mr4">
                            <router-link :to="{ name: 'RawMaterial' }" exact-active-class="active-menu">
                                Raw Material
                            </router-link>
                        </div>
                        <div v-if="state.wipStatus" class="mr4">
                            <router-link :to="{ name: 'WorkInProgress' }" exact-active-class="active-menu">
                                Work in Progress
                            </router-link>
                        </div>
                        <!-- <div class="mr4">
                            <router-link :to="{ name: 'Production' }" exact-active-class="active-menu">
                                Finished Product
                            </router-link>
                        </div> -->
                    </div>
                </div>
                <!-- summary all product, draft archieve -->
            </div>

            <router-view />
        </template>
    </app-wrapper>
</template>

<script>
import { onMounted, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import AppWrapper from '@/layout/AppWrapper'
import CreateProduct from '@/components/CreateProduct'
import AccessDenied from '@/components/AccessDenied'

export default {
    name: 'Reports',
    components: { AppWrapper, CreateProduct, AccessDenied },

    setup() {
        const store = useStore()
        const plan = computed(() => store.state?.Settings?.paymentPlan)

        const initialState = () => ({
            wipStatus: false,
        })
        const state = reactive(initialState())

        // const profits = computed(() => store.state.Reporting.profits)

        const createProductDropDown = () => {
            document.getElementById('profile-dropdown').classList.toggle('show')
        }

        const changeBusinessType = (type) => {
            store.dispatch('DashboardStats/getBusinessType', type)
        }

        const toggleCreateProduct = (payload) => {
            store.dispatch('Inventory/showCreateProduct', payload)
        }

        onMounted(() => {
            store.dispatch('Settings/getPlan')
            // store.dispatch('Reporting/getReportingProfits')
            store.dispatch('Settings/getAccountSettings').then((data) => {
                if (data.status) {
                    state.wipStatus = data?.data?.wipStatus || false
                }
            })
        })

        return {
            state,
            createProductDropDown,
            changeBusinessType,
            toggleCreateProduct,
            plan,
        }
    },
}
</script>

<style scoped></style>
